import { isProductionStage } from "@library/project/envs";
import { removeCookie } from "./cookies";

const LAST_RESET_KEY = "reset";
const LAST_RESET_VALUE = "r5";

interface Args {
  cookiesKeys: string[];
  sessionStorageKeys: string[];
  localStorageKeys: string[];
}

export const resetStorage = ({ cookiesKeys, localStorageKeys, sessionStorageKeys }: Args) => {
  const lastResetValue = localStorage.getItem(LAST_RESET_KEY);

  if (lastResetValue === LAST_RESET_VALUE || isProductionStage) return;

  cookiesKeys.forEach((cookieKey) => removeCookie(cookieKey));

  sessionStorageKeys.forEach((sesStorageKey) => sessionStorage.removeItem(sesStorageKey));

  localStorageKeys.forEach((locStorageKey) => localStorage.removeItem(locStorageKey));

  localStorage.setItem(LAST_RESET_KEY, LAST_RESET_VALUE);
};
